<template>
  <div
    class="form-wrapper"
    style="border-radius: 5px; padding: 12px; box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.05);"
  >
    <h3 class="font-weight-bolder text-body">
      {{ $t(SUPPLIER_INFORMATION_TITLE) }}
    </h3>
    <!--    <b-form>-->
    <b-row class="mt-1">
      <b-col class="12">
        <select-account
          ref="selectAccount"
          :customer-i-d="customerID"
          :auto-suggest-table-columns="suggestionTableColumnsSupplier"
          :module-name-orders="MODULE_NAME_ORDERS"
          :module-name="MODULE_NAME"
          :is-search-button="true"
          :default-search-query="searchQuery"
          label="Customer Name"
          :is-editable="isNotChangeable"
          @changedCustomerId="selectHandler"
          @selectHandler="setCustomerId"
          @onSearchEmpty="onSearchEmpty"
          @onInput="onInput"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col class="6 mt-1">
        <component
          :is="customerInformationFields[field].type"
          v-for="field in ['billing_contact']"
          :key="field"
          v-model="customerInformation[field]"
          v-bind="getProps(field, customerInformationFields, true, 'billing_contact', getParentValue)"
          :is-editable="isNotChangeable"
          @input="getBillingContact($event, field)"
        />
        <component
          :is="customerInformationFields[field].type"
          v-for="field in ['billing_phone', 'billing_email']"
          :key="field"
          :ref="`${field}_ref`"
          v-model="customerInformation[field]"
          class="h-auto"
          :is-editable="true"
          v-bind="getProps(field, customerInformationFields)"
        />
        <div :class="`${!hasAddress ? 'billing_address-border-danger':''}`">
          <component
            :is="customerInformationFields['billing_address'].type"
            ref="billing_address_ref"
            v-model="customerInformation['billing_address']"
            class="h-auto"
            :is-editable="true"
            v-bind="getProps('billing_address', customerInformationFields)"
          />
        </div>
        <component
          :is="customerInformationFields['payment_term_id'].type"
          ref="payment_term_id_ref"
          v-model="customerInformation['payment_term_id']"
          v-bind="getProps('payment_term_id', customerInformationFields)"
          :is-editable="isNotChangeable"
        />
        <div
          class="alert alert-danger"
          style="max-width: max-content; padding: 3px"
        >
          {{ $t('Linked Order TBD') }}
        </div>
        <component
          :is="customerInformationFields['linked_order_id'].type"
          ref="payment_term_id_ref"
          v-model="customerInformation['linked_order_id']"
          v-bind="getProps('linked_order_id', customerInformationFields)"
          :is-editable="isNotChangeable"
        />
      </b-col>
      <b-col class="6 mt-1">
        <attachment :accept-extension-array="['.jpg', '.jpeg', '.png', '.tif', '.pdf', '.bmp']" />

        <component
          :is="customerInformationFields['internal_account_notes'].type"
          ref="internal_account_notes_ref"
          v-model="customerInformation['internal_account_notes']"
          :is-editable="true"
          class="h-auto"
          v-bind="getProps('internal_account_notes', customerInformationFields)"
        />
        <component
          :is="customerInformationFields[field].type"
          v-for="field in ['internal_order_reference']"
          :key="field"
          :ref="`${field}_ref`"
          v-model="customerInformation[field]"
          class="h-auto"
          v-bind="getProps(field, customerInformationFields)"
          :is-editable="isNotChangeable"
        />
      </b-col>
    </b-row>
    <!--    </b-form>-->
  </div>

</template>

<script >
import config from '@/views/main/orders/view/credit-note/config'
import SelectAccount from '@/views/main/orders/components/use-as-global/SelectAccount.vue'
import { getProps, getValueFromGivenObjectByKey } from '@/@core/utils/utils'

import { BCol, BRow, BForm } from 'bootstrap-vue'
import Attachment from '@/views/main/orders/view/credit-note/components/Attachment.vue'

export default {
  name: 'SupplierInformation',
  components: {
    Attachment,
    SelectAccount,
    BCol,
    BRow,
    BForm,
  },
  props: {
    supplierId: {
      type: [String, Number],
      default: 0,
    },
  },
  data() {
    return {
      hasAddress: true,
      searchQuery: '',
    }
  },

  computed: {
    customerID() {
      return this.$store.state[this.MODULE_NAME].customerInformationForm.customer_id
    },
    customerInformation() {
      return this.$store.state[this.MODULE_NAME].customerInformationForm
    },
    isNotChangeable() {
      return this.quote.state === 2 || (this.quote.state === 0 && this.quote.status === 5)
    },
    quote() {
      return this.$store.state[this.MODULE_NAME].quote
    },
  },
  watch: {
    customerInformation(val) {
      if (val) {
        this.searchQuery = val.customer?.cust_name_dba
      }
    },
  },
  methods: {
    getParentValue() {
      return this.customerInformation.customer_id
    },
    getBillingContact(obj, field) {
      if (field !== 'billing_contact' && !obj) return false
      this.$store.commit(`${this.MODULE_NAME}/SET_CUSTOMER_INFORMATION_FORM`, {
        ...this.customerInformation,
        billing_phone: getValueFromGivenObjectByKey(obj, 'phone_one'),
        billing_email: getValueFromGivenObjectByKey(obj, 'email'),
      })
    },
    onInput(searchQuery) {
      this.searchQuery = searchQuery
    },
    // setAccessForAppearingForms() {
    //   const access = !!this.getParentValue.customer_id
    //   this.$store.commit(`${this.MODULE_NAME}/SET_CUSTOMER_INFORMATION_FORM`, access)
    // },
    // resetSelectedAccount() {
    //   this.getParentValue = null
    //   this.setAccessForAppearingForms()
    // },
    setCustomerId(item) {
      this.$store.commit(`${this.MODULE_NAME}/SET_CUSTOMER_INFORMATION_FORM`, {
        ...this.customerInformation,
        customer_id: item.id,
      })
    },
    selectHandler(item) {
      this.hasAddress = true

      const {
        customer_detail,
        default_address,
      } = item.item

      if (default_address === null) {
        this.hasAddress = false
      }

      this.$store.commit(`${this.MODULE_NAME}/SET_CUSTOMER_INFORMATION_FORM`, {
        ...this.customerInformation,
        billing_address: this.hasAddress ? `${getValueFromGivenObjectByKey(default_address, 'street', '—')}, ${getValueFromGivenObjectByKey(default_address, 'state.name', '—')}, ${getValueFromGivenObjectByKey(default_address, 'state.code', '—')},  ${getValueFromGivenObjectByKey(default_address, 'zip', '—')}` : 'Customer Missing Address',
        billing_address_id: this.hasAddress ? default_address.id : 0,
        internal_account_notes: getValueFromGivenObjectByKey(customer_detail, 'comments', ''),
        // tax_rule: getValueFromGivenObjectByKey(customer_detail, 'tax_rule', null),
      })
    },
    onSearchEmpty() {
      this.$store.commit(`${this.MODULE_NAME}/SET_CUSTOMER_INFORMATION_FORM`, {
        attachments: [],
      })
      this.hasAddress = true
    },
  },
  setup() {
    const {
      MODULE_NAME,
      MODULE_NAME_ORDERS,
      SUPPLIER_INFORMATION_TITLE,
      suggestionTableColumnsSupplier,
      customerInformationFields,
    } = config()

    return {
      getProps,
      MODULE_NAME,
      MODULE_NAME_ORDERS,
      SUPPLIER_INFORMATION_TITLE,
      suggestionTableColumnsSupplier,
      customerInformationFields,
    }
  },
}
</script>

<style>

</style>
